import Vue from 'vue'
import VueCompositionAPI from '@vue/composition-api'

// Global 3rd party plugins
import PerfectScrollbar from 'perfect-scrollbar'

/*
 * Vue 3rd party plugins
 * Note: Added in order when they were required
 */
import './core/plugins/bootstrap-vue'
import './core/plugins/inline-svg'
import './core/plugins/metronic' // CORE global functionality for the Metronic theme
import './core/plugins/perfect-scrollbar'
import './core/plugins/moment' // datetime to nicetime

// App core
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import { store } from './store'

// Filters
import { capitalize } from './core/filters/capitalize'
import { truncate } from './core/filters/truncate'

// Global 3rd party plugins enable
window.PerfectScrollbar = PerfectScrollbar

Vue.config.productionTip = false
Vue.config.devtools = process.env.NODE_ENV !== 'production'

// Global usages
Vue.use(VueCompositionAPI)

// Global filters
Vue.filter('capitalize', capitalize)
Vue.filter('truncate', truncate)

let app = false
const fb = require('./firebaseConfig')

fb.auth.onAuthStateChanged(() => {
  if (!app) {
    app = new Vue({
      router,
      store,
      render: (h) => h(App)
    }).$mount('#app')
  }
})
