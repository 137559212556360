import Vue from 'vue'
import moment from 'moment'

moment.locale('nb')

const momentjs = () => Object.defineProperties(Vue.prototype, {
  $moment: {
    get () {
      return moment
    }
  }
})

Vue.use(momentjs)
