import Vue from 'vue'
import VueRouter from 'vue-router'
import { store } from '@src/store'
import { GET_ALL, LOGIN } from '@src/store/auth'

const fb = require('@src/firebaseConfig')

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import('@src/layouts/Auth'),
    children: [
      {
        name: 'root',
        path: '/',
        redirect: '/login'
      },
      {
        name: 'logginn',
        path: '/logginn',
        redirect: '/login'
      },
      {
        name: 'login',
        path: '/login',
        component: () => import('@src/views/Login')
      }
    ]
  },
  {
    path: '/',
    component: () => import('@src/layouts/Default'),
    children: [
      {
        name: 'dashboard',
        path: '/dashboard',
        component: () => import('@src/views/Dashboard'),
        meta: { requiresAuth: true }
      },
      {
        name: 'tasks',
        path: '/tasks',
        component: () => import('@src/views/tasks/Index'),
        meta: { requiresAuth: true }
      },
      {
        name: 'customers-index',
        path: '/customers',
        component: () => import('@src/views/customers/Index'),
        meta: {
          requiresAuth: true
        }
      },
      {
        name: 'customers-sync',
        path: '/customers/sync',
        component: () => import('@src/views/customers/Synchronize'),
        meta: {
          requiresAuth: true
        }
      },
      {
        name: 'sessions-index',
        path: '/sessions',
        component: () => import('@src/views/sessions/Index'),
        meta: {
          requiresAuth: true
        }
      },
      {
        name: 'templates-index',
        path: '/templates',
        component: () => import('@src/views/templates/Index'),
        meta: {
          requiresAuth: true
        }
      },
      {
        name: 'template-edit',
        path: '/templates/edit/:templateId',
        component: () => import('@src/views/templates/Edit/Index'),
        meta: {
          requiresAuth: true
        }
      },
      {
        name: 'template-edit-styletypes',
        path: '/templates/edit/:templateId/styletypes',
        component: () => import('@src/views/templates/Edit/Styletypes'),
        meta: {
          requiresAuth: true
        }
      },
      {
        name: 'template-edit-designer',
        path: '/templates/edit/:templateId/designer',
        component: () => import('@src/views/templates/Edit/Designer'),
        meta: {
          requiresAuth: true
        }
      },
      {
        name: 'template-edit-settings',
        path: '/templates/edit/:templateId/settings',
        component: () => import('@src/views/templates/Edit/Settings'),
        meta: {
          requiresAuth: true
        }
      },
      {
        name: 'products-index',
        path: '/products',
        component: () => import('@src/views/products/Index'),
        meta: {
          requiresAuth: true
        }
      },
      {
        name: 'products-sync',
        path: '/products/sync',
        component: () => import('@src/views/products/Synchronize'),
        meta: {
          requiresAuth: true
        }
      },
      {
        name: 'logs-index',
        path: '/logs',
        component: () => import('@src/views/logs/Index'),
        meta: {
          requiresAuth: true
        }
      },
      {
        name: 'logs-products-sync',
        path: '/logs/products/sync/:logId',
        component: () => import('@src/views/logs/products/Item'),
        meta: {
          requiresAuth: true
        }
      },
      {
        name: 'logs-customers-sync',
        path: '/logs/customers/sync/:logId',
        component: () => import('@src/views/logs/customers/Item'),
        meta: {
          requiresAuth: true
        }
      },
      {
        name: 'logs-template-delete',
        path: '/logs/templates/deleted/:logId',
        component: () => import('@src/views/logs/templates/Deleted'),
        meta: {
          requiresAuth: true
        }
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // Firebase Auth
  const requiresAuth = to.matched.some((x) => x.meta.requiresAuth)
  const { currentUser } = fb.auth

  if (currentUser) {
    // User - if a logged in user exists, we need to make sure the additional data from Firestore exists
    if (store.getters[GET_ALL] === null) {
      store.dispatch(LOGIN, { user: currentUser, mode: 'auto' }) // eslint-disable-line object-property-newline, max-len
    }

    /* const user = store.getters[GET_ALL] !== null
      ? store.getters[GET_ALL]
      : store.dispatch(LOGIN, { user: currentUser, mode: 'auto' })

    // If any errors from vuex LOGIN action
    if (user.code !== undefined) {
      store.commit(SET_ERROR, { message: 'custom/missing-user-in-db' })
      await store.dispatch(LOGOUT_FROM_FB)
      return next('/login')
    } */
  }

  if (requiresAuth && !currentUser) {
    return next('/login')
  } else if (!requiresAuth && currentUser) {
    return next('/dashboard')
  }

  return next()
})

export default router
