//
// namespaceHelper
//

// Removes the namespaced param string from all values in types

export default function removeNamespace (namespace, types) {
  return Object.keys(types).reduce((typeObj, typeCat) => {
    typeObj[typeCat] = Object.keys(types[typeCat]).reduce((obj, key) => {
      obj[key] = types[typeCat][key].replace(namespace, '')
      return obj
    }, {})

    return typeObj
  }, {})
}
