<template>
  <router-view></router-view>
</template>

<script>
  export default { name: 'App' }
</script>

<style lang="scss">
  // 3rd party plugins css
  //@import "~perfect-scrollbar/css/perfect-scrollbar.css";
  //@import "~line-awesome/dist/line-awesome/css/line-awesome.min.css";
  @import "~@/assets/plugins/keenthemes-icons/font/ki.css";
  @import "~@/assets/plugins/flaticon/flaticon.css";
  @import "~@/assets/plugins/flaticon2/flaticon.css";
  @import "~@src/assets/plugins/fontawesome-pro/css/all.min.css";

  // Global styles
  @import './assets/sass/style.scss';
</style>
