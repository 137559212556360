import removeNamespace from './helpers/namespaceHelper'

// Setting up PUBLIC namespaced typenames
export const SETTINGS = {
  // action types
  ACTIONS: {
    SET_ACTIVE_SUBMENU: 'settings/setActiveSubmenu',
    SET_SUBMENU_DATA: 'settings/setSubmenuData'
  },
  MUTATIONS: {
    SET_ACTIVE_SUBMENU: 'settings/setActiveSubmenu',
    SET_SUBMENU_DATA: 'settings/setSubmenuData'
  },
  GETTERS: {
    GET_ACTIVE_SUBMENU: 'settings/getActiveSubmenu',
    GET_SUBMENU_DATA: 'settings/getSubmenuData'
  }
}

// Setting up PRIVATE typenames
const _TYPES = removeNamespace('settings/', SETTINGS)
const namespaced = true

const state = {
  submenu: {
    name: 'Dashboard',
    data: {}
  }
}

const getters = {
  [_TYPES.GETTERS.GET_ACTIVE_SUBMENU]: (state) => `SubHeader${ state.submenu.name}`,
  [_TYPES.GETTERS.GET_SUBMENU_DATA]: (state) => state.submenu.data
}

const actions = {
  [_TYPES.ACTIONS.SET_ACTIVE_SUBMENU] (state, menuname) {
    state.commit(_TYPES.MUTATIONS.SET_ACTIVE_SUBMENU, menuname)
  },
  [_TYPES.ACTIONS.SET_SUBMENU_DATA] (state, data) {
    state.commit(_TYPES.MUTATIONS.SET_SUBMENU_DATA, data)
  }
}

const mutations = {
  [_TYPES.MUTATIONS.SET_ACTIVE_SUBMENU] (state, menuname) {
    state.submenu.name = menuname
  },
  [_TYPES.MUTATIONS.SET_SUBMENU_DATA] (state, data) {
    state.submenu.data = data
  }
}

export default {
  namespaced,
  state,
  actions,
  mutations,
  getters
}
