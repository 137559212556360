// Keenthemes plugins - custom imported
import KTUtil from '@/assets/js/components/util.js'
import KTToggle from '@/assets/js/components/toggle.js'
import KTCookie from '@/assets/js/components/cookie.js'
import KTOffcanvas from '@/assets/js/components/offcanvas.js'
import KTMenu from '@/assets/js/components/menu.js'
import KTScrolltop from '@/assets/js/components/scrolltop.js'
import KTLayoutAside from '@/assets/js/layout/base/aside.js'

window.KTUtil = KTUtil
window.KTToggle = KTToggle
window.KTCookie = KTCookie
window.KTOffcanvas = KTOffcanvas
window.KTMenu = KTMenu
window.KTScrolltop = KTScrolltop
window.KTLayoutAside = KTLayoutAside
