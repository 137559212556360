/*
 Capitalize Filter
 Install:
  import { capitalize } from './core/filters/capitalize'
  Vue.filter('capitalize', capitalize)
 Usage:
  {{ text | capitalize }}
 */

export const capitalize = (string) => {
  if (!string) return ''

  const value = string.toString()
  return value.charAt(0).toUpperCase() + value.slice(1)
}
