import Vue from 'vue'
import Vuex from 'vuex'

// Metronic stores
import htmlClass from '@/core/services/store/htmlclass.module'

// Custom stores
import breadcrumbs from './breadcrumbs' // Modified version of Metronics breadcrumbs
import config from './config' // Modified version of Metronics config.module
import auth from './auth'
import settings from './settings'
import tasks from './tasks'

// const fb = require('@src/firebaseConfig')
Vue.use(Vuex)

// Create the store
const store = new Vuex.Store({
  modules: {
    breadcrumbs,
    config,
    auth,
    settings,
    htmlClass,
    tasks
  }
})

export { store }
