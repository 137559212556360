/*
 * Modified auth.js store from the Metronic Theme
 * Keeping this no-namespaced to optimize theme compability (!important)
 */

import Vue from 'vue'
import router from '@src/router'
import objectPath from 'object-path'
const objectPathWIP = objectPath.create({ includeInheritedProps: true })

// getter types
export const GET_ALL = 'getAll'
export const GET_AUTH_STATE = 'getAuthState'

// action types
export const LOGIN = 'login'
export const LOGOUT = 'logout'
export const LOGOUT_FROM_FB = 'logoutFromFb'
const GET_AUTH_USERDATA = 'getAuthData'
const LOGIN_USER = 'loginUser'

// mutations types
const PURGE_AUTH = 'logOut'
const SET_AUTH = 'setUser'
const SET_DATA = 'setData'
const SET_AUTH_READY = 'setAuthReady'
export const SET_ERROR = 'setError'

const fb = require('@src/firebaseConfig')

const state = () => ({
  errors: {},
  user: {}, // Firebase user data (Firebase auth system)
  data: null, // Firestore DB (users) data
  isAuthenticated: false,
  isReady: false,
  test: true
})

const getters = {
  [GET_ALL] (state) {
    return Object.keys(state.user).length > 0 && state.data !== null
      ? {
          user: state.user, // Firebase (auth system)
          data: state.data // Firestore (db)
        }
      : null
  },
  [GET_AUTH_STATE] (state) {
    return state.isReady
  }
}

const actions = {
  async [LOGIN] (context, data) {
    if (data.user !== undefined) {
      console.log('[VUEX :: Auth] Login')

      const authUser = data.user // Firebase Auth system userdata
      const mode = objectPathWIP.get(data, 'mode', 'auto') === 'manually' ? LOGIN_USER : GET_AUTH_USERDATA
      const authData = await context.dispatch(mode) // Firestore DB userdata (users)
      if (authData.errors !== undefined) {
        await context.dispatch(LOGOUT_FROM_FB) // Logout from Firebase if login fails
        if (mode === 'auto') {
          // From anywhere in the app
          context.commit(SET_ERROR, { message: 'custom/missing-user-in-db' }, { root: true })
          router.push('/login')
        } else {
          // From the login-screen
          throw {
            code: 'custom/missing-user-in-db',
            servermsg: authData.errors
          }
        }
      }

      context.commit(SET_DATA, authData) // Firestore DB userdata (users)
      context.commit(SET_AUTH, authUser) // Firebase Auth system userdata
      context.commit(SET_AUTH_READY) // Login done!

      return {
        auth: authUser,
        data: authData
      }
    }

    console.error('[VUEX] Login: ERROR, user is required ')
    return false
  },
  [LOGOUT] (context) {
    console.log('[VUEX :: Auth] Logout')
    context.commit(PURGE_AUTH)
  },
  [LOGOUT_FROM_FB] (context) {
    return new Promise((resolve, reject) => {
      console.log('[VUEX :: Auth] Logout from FB')
      fb.auth.signOut().then(() => {
        resolve(context.dispatch(LOGOUT))
      }).catch((err) => {
        reject(err)
      })
    })
  },
  /**
   * LOGIN_USER
   * Basically the same as "GET_AUTH_USERDATA", except the cloud-function has some
   * additional login-features
   */
  async [LOGIN_USER] () {
    const loginUser = await fb.functions.httpsCallable('backendUserLoginUser')
    return await loginUser().then((res) => {
      if (objectPathWIP.has(res, 'data.success')) {
        return res.data.result
      }

      return res.data // including errors
    }).catch((err) => {
      console.log('[VUEX :: Auth] ERROR: Could not fetch the user', err)
      return err
    })
  },
  async [GET_AUTH_USERDATA] () {
    const getUser = await fb.functions.httpsCallable('backendUserGetUser')
    return await getUser().then((res) => {
      console.log('Fetching USERDATA:', res)
      if (objectPathWIP.has(res, 'data.success')) {
        return res.data.result
      }

      return res.data // including errors
    }).catch((err) => {
      console.log('[VUEX :: Auth] ERROR: Could not fetch the user', err)
      return err
    })
  }
}

const mutations = {
  [SET_ERROR] (state, error) {
    Vue.set(state, 'errors', error)
  },
  [SET_AUTH] (state, user) {
    state.isAuthenticated = true
    state.user = user
    state.errors = {}
  },
  [SET_DATA] (state, data) {
    Vue.set(state, 'data', data)
  },
  [PURGE_AUTH] (state) {
    state.isAuthenticated = false
    state.user = {}
    state.errors = {}
    state.data = null
    state.isReady = false
  },
  [SET_AUTH_READY] (state) {
    state.isReady = true
  }
}

export default {
  state,
  actions,
  mutations,
  getters
}
