import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import 'firebase/compat/storage'
import 'firebase/compat/functions'
import 'firebase/compat/analytics'
import 'firebase/compat/auth'

let config = {}
console.log(`[APP] App is running in ${process.env.VUE_APP_NODE_ENV} mode`)


if (process.env.VUE_APP_NODE_ENV === 'prod') {
  // Production mode
  config = {
    apiKey: 'AIzaSyBCu3riA-UtFLmLxALTJUwuZQxt-OofEK4',
    authDomain: 'sg-showroom-prod.firebaseapp.com',
    projectId: 'sg-showroom-prod',
    storageBucket: 'sg-showroom-prod.appspot.com',
    messagingSenderId: '56749947924',
    appId: '1:56749947924:web:8e9a3137b26763f8667b01',
    measurementId: 'G-CT2CVE7D2W'
  }
} else {
  // Development mode
  config = {
    apiKey: 'AIzaSyATNKXvQiSIf-NE3hkwj93gqvUMrfA5s8w',
    authDomain: 'sg-showroom-dev.firebaseapp.com',
    projectId: 'sg-showroom-dev',
    storageBucket: 'sg-showroom-dev.appspot.com',
    messagingSenderId: '745122981324',
    appId: '1:745122981324:web:8a075768bf4d6daf342969'
  }
}

// Firebase init goes here
!firebase.apps.length && firebase.initializeApp(config)
if (process.env.VUE_APP_NODE_ENV === 'prod') firebase.analytics()

// Firebase utils
const auth = firebase.auth()
const storage = firebase.storage()
// const functions = firebase.functions()
const functions = firebase.app().functions('europe-west2') // Set the functions region to the correct one
const db = firebase.firestore()

// Set up development modes
if (process.env.VUE_APP_LOCAL_DEVELOPMENT === 'enabled') {
  console.info('IMPORTANT: Running local FUNCTIONS and local DATABASE mode')

  // functions.useEmulator('localhost', 5001) // Single computer development
  functions.useEmulator('192.168.68.54', 5001) // Local network development

  // auth.useEmulator('http://localhost:9099') // Single computer development
  auth.useEmulator('http://192.168.68.54:9099') // Local network development

  db.settings({
    // host: '0.0.0.0:8081', // Single computer development
    host: '192.168.68.54:8081', // Local network development
    ssl: false
  })
}

// Firebase collections (only for snapshots - all others are fetched via API)
const snapsCollection = db.collection('snaps')

export {
  snapsCollection,
  config,
  firebase,
  auth,
  storage,
  functions,
  db
}
