// A COPY OF METRONICs BREADCRUMBS.MODULE.JS with slight modifications

// action types
export const APPEND_BREADCRUM = 'appendBreadcrumb'

// mutation types
export const SET_BREADCRUMB = 'setBreadcrumb'
export const ADD_BREADCRUMB = 'addBreadcrumb'

export default {
  state: { breadcrumbs: [] },
  getters: {
    /**
     * Get list of breadcrumbs for current page
     * @param state
     * @returns {*}
     */
    breadcrumbs (state) {
      return state.breadcrumbs
    },

    /**
     * Get the page title
     * @param state
     * @returns {*}
     */
    pageTitle (state) {
      // VARY MODIFIED
      const title = state.breadcrumbs.reduce((breadcrumbTitle, current) => {
        if (current.isTitle !== undefined) {
          return current.title
        }
        return breadcrumbTitle
      }, '')

      if (title === '' || title === undefined) {
        const last = state.breadcrumbs[state.breadcrumbs.length - 1]
        if (last && last.title) {
          return last.title
        }
      } else {
        return title
      }
    }
  },
  actions: {
    /**
     * Set the breadcrumbs list
     * @param state
     * @param payload
     */
    [SET_BREADCRUMB] (state, payload) {
      state.commit(SET_BREADCRUMB, payload)
    },

    /**
     * Add breadcrumb
     * @param state
     * @param payload
     */
    [ADD_BREADCRUMB] (state, payload) {
      if (typeof payload === 'object') {
        payload.forEach((item) => state.commit(APPEND_BREADCRUM, item))
      } else {
        state.commit(APPEND_BREADCRUM, payload)
      }
    }
  },
  mutations: {
    [APPEND_BREADCRUM] (state, payload) {
      state.breadcrumbs = [
        ...state.breadcrumbs,
        payload
      ]
    },
    [SET_BREADCRUMB] (state, payload) {
      state.breadcrumbs = payload
    }
  }
}
