import Vue from 'vue'
import removeNamespace from './helpers/namespaceHelper'
import * as objectPath from 'object-path'

const fb = require('@src/firebaseConfig')

const taskStatuses = {
  0: 'active',
  1: 'completed',
  2: 'skipped',
  3: 'deleted',
  4: 'unknown'
}

// PUBLIC namespaced typenames
export const TASKS = {
  ACTIONS: {
    FETCH_TASKS: 'tasks/fetchTasks'
  },
  MUTATIONS: {
    SET_TASK: 'tasks/setTasks',
    PURGE_TASKS: 'tasks/purgeTasks'
  },
  GETTERS: {
    GET_ACTIVE: 'tasks/getActive'
  }
}

// Setting up PRIVATE typenames
const _TYPES = removeNamespace('tasks/', TASKS)
const namespaced = true

const state = () => ({
  active: {},
  completed: {},
  skipped: {},
  deleted: {},
  unknown: {}
})

const getters = {
  [_TYPES.GETTERS.GET_ACTIVE] (state) {
    return state.active
  }
}

const actions = {
  async [_TYPES.ACTIONS.FETCH_TASKS] (context, data) {
    const status = objectPath.get(data, 'status', false) // false fetches all tasks
    const limit = objectPath.get(data, 'limit', false) // false fetches all tasks - no pagination

    const getTasks = fb.functions.httpsCallable('backendTasksGetTasks')
    await getTasks({
      data: {
        status,
        limit
      }
    }).then((res) => {
      context.commit(_TYPES.MUTATIONS.PURGE_TASKS)
      const tasks = objectPath.get(res, 'data.result', {})

      Object.keys(tasks).forEach((taskKey) => {
        const task = objectPath.get(tasks, taskKey)

        if (task) {
          const taskStatus = objectPath.get(tasks, `${ taskKey }.status`, 0)
          context.commit(_TYPES.MUTATIONS.SET_TASK, {
            status: objectPath.get(taskStatuses, taskStatus, 4),
            task
          })
        } else {
          console.log('Could not find the task')
        }
      })
    })
  }
}

const mutations = {
  [_TYPES.MUTATIONS.SET_TASK] (state, data) {
    const status = objectPath.get(data, 'status', 'unknown')
    const task = objectPath.get(data, 'task', null)
    const taskId = objectPath.get(data, 'task.id', null)

    const currentTasks = state[status]

    if (taskId) {
      const newTasklist = { ...currentTasks, [taskId]: task } // eslint-disable-line object-property-newline
      Vue.set(state, status, newTasklist)
    } else {
      console.log('Could not set TASK, missing task-ID')
    }
  },
  [_TYPES.MUTATIONS.PURGE_TASKS] (state) {
    state.active = {}
    state.completed = {}
    state.skipped = {}
    state.deleted = {}
    state.unknown = {}
  }
}

export default {
  namespaced,
  state,
  actions,
  mutations,
  getters
}
